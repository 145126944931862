@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: #f8f7f1;
  font-family: "Almarai", sans-serif;
}
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
  outline: transparent;
}

a:focus,
a:visited,
a:active {
  outline: none;
}

p {
  line-height: normal;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(224, 224, 224);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d6dee1;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a8bbbf;
}

.carsSideAnimation {
  transition: 0.5s;
}

.container div {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  gap: 8px;
}
.container input {
  width: 48px;
  height: 48px;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  color: #121212;
  border: 1px solid #cacaca;
  border-radius: 8px;
  background: #fff;
  background-clip: padding-box;
}
@media only screen and (max-width: 600px) {
  .container input {
    width: 44px;
    height: 44px;
  }
}

.error input {
  color: #be202e;
  border: 1px solid #be202e;
  border-radius: 8px;
  background: #fff;
  background-clip: padding-box;
  outline: 3px solid #be202e33;
}

/* ENDLESS ROTATE */
.rotate {
  animation: rotate 1s linear infinite;
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev,
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  color: white;
  background-color: #231f20b3;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  margin-inline-start: 2px;
  font-size: 16px;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  margin-inline-start: -2px;
  font-size: 16px;
}

.swiper-pagination {
  line-height: 22px;
  padding: 0px 8px;
  margin: auto;
  left: 0px;
  right: 0px;
  width: fit-content !important;
  height: 24px;
  border-radius: 12px;
  background: #231f2080;
}

.rts___tabs {
  padding: 16px;
  padding-bottom: 0px;
}
.rts___tab,
.rts___btn {
  border-radius: 0px;
  padding: 0px;
  border: 0px;
  cursor: auto;
}
.rts___tab___selected {
  color: #231f20;
  box-shadow: none;
}
